import React, { Component } from "react";
import { Container, Row, Col } from "react-bootstrap";

import pfp from "../images/pfp.jpg";

class StatusMessageComponent extends Component {
  state = {};
  render() {
    return (
      <React.Fragment>
        <Container style={{ paddingTop: "100pt" }}>
          <Row>
            <Col xs="auto">
              <img
                src={pfp}
                style={{
                  width: "200pt",
                  borderRadius: "50%",
                  borderColor: "orange",
                  borderWidth: "2.5 pt",
                  borderStyle: "solid",
                }}
              />
            </Col>
            <Col>
              <p style={{ paddingTop: "40pt", color: "lightgrey" }}>
                <h1 style={{ color: "orange" }}>Marvin Janosch</h1>
                <h2>Software Ingenieur</h2>
                <p>
                  Diese Webseite befindet sich gerade im Aufbau.
                  <br />
                  Schauen Sie doch später noch einmal vorbei oder klicken Sie
                  auf die Symbole in der Navigationsleiste um meine Profile und
                  Storeseiten zu besuchen!
                </p>
                <p>
                  <span role="img" aria-label="Briefumschlag Emoji">
                    📨{" "}
                  </span>
                  <span>
                    <a href="mailto:anfrage@janosch.koeln?subject=Anfrage%20über%20Janosch.Koeln">
                      anfrage@janosch.koeln
                    </a>
                  </span>
                </p>
              </p>
            </Col>
          </Row>
        </Container>
      </React.Fragment>
    );
  }
}

export default StatusMessageComponent;
