import React, { Component } from "react";

import {
  Badge,
  Collapse,
  Navbar,
  NavbarToggler,
  NavbarBrand,
  Nav,
  NavItem,
  NavLink,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap";

import ziegenBild from "../images/goat.svg";

import xingIcon from "../images/icons/svg/xing.svg";
import linkedinIcon from "../images/icons/svg/linkedin.svg";
import githubIcon from "../images/icons/svg/github.svg";
import playstoreIcon from "../images/icons/svg/playstore.svg";
import microsoftstoreIcon from "../images/icons/svg/microsoftstore.svg";

class NavbarComponent extends Component {
  constructor(props) {
    super(props);

    this.toggle = this.toggle.bind(this);
    this.state = {
      isOpen: false,
    };
  }

  toggle() {
    this.setState({
      isOpen: !this.state.isOpen,
    });
  }

  render() {
    return (
      <Navbar color="primary" dark expand="md">
        <NavbarBrand href="/">
          <h3>
            <img src={ziegenBild} /> <Badge color="secondary">JANOSCH</Badge>{" "}
            koeln
          </h3>
        </NavbarBrand>
        <NavbarToggler onClick={this.toggle} />
        <Collapse isOpen={this.state.isOpen} navbar>
          <Nav className="ml-auto" navbar>
            <NavItem>
              <NavLink href="https://www.xing.com/profile/Marvin_Janosch">
                <img src={xingIcon} alt="Xing" className="navbarIcon" />
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink href="https://www.linkedin.com/in/marvin-janosch-13082354">
                <img src={linkedinIcon} alt="LinkedIn" className="navbarIcon" />
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink
                href="https://github.com/Waldemarv"
                style={{ paddingRight: "20pt" }}
              >
                <img src={githubIcon} alt="Github" className="navbarIcon" />
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink href="https://play.google.com/store/apps/developer?id=Janosch.Koeln">
                <img
                  src={playstoreIcon}
                  alt="Google PlayStore"
                  className="navbarIcon"
                />
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink
                href="https://www.microsoft.com/de-de/search/shop/Apps?q=Janosch.Koeln"
                style={{ paddingRight: "20pt" }}
              >
                <img
                  src={microsoftstoreIcon}
                  alt="Microsoft Store"
                  className="navbarIcon"
                />
              </NavLink>
            </NavItem>
            {/* <UncontrolledDropdown nav inNavbar>
              <DropdownToggle nav caret>
                Options
              </DropdownToggle>
              <DropdownMenu right>
                <DropdownItem>Noch nichts</DropdownItem>
                <DropdownItem divider />
                <DropdownItem>
                  hier{" "}
                  <span role="img" aria-label="Bier Prost Emoji">
                    🍻
                  </span>
                </DropdownItem>
              </DropdownMenu>
            </UncontrolledDropdown> */}
          </Nav>
        </Collapse>
      </Navbar>
    );
  }
}

export default NavbarComponent;
